import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { useFirebase } from "react-redux-firebase"
import Container from "react-bootstrap/Container"
import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Card from "react-bootstrap/Card"
import Button from "react-bootstrap/Button"
import Page from "../components/Page"
import FancyHeader from "../components/blocks/FancyHeader"
import { useAuthV2 } from "../hooks/useAuth"

const EventPage = ({ documentID }) => {
  const { signInAnonymously, authUser } = useAuthV2()

  React.useEffect(() => {
    signInAnonymously().catch(console.error)
  }, [signInAnonymously])

  let firebase = React.useMemo(() => ({}), [])
  if (typeof window !== "undefined") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    firebase = useFirebase()
  }

  const [imageUrl, setImageUrl] = useState(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(null)
  const [theEvent, setEvent] = useState(null)

  useEffect(() => {
    if (!authUser) {
      return
    }

    firebase
      .firestore()
      .collection("events")
      .doc(documentID)
      .get()
      .then((doc) => {
        setLoading(false)

        if (!doc.exists) {
          setError("This Rallista event doesn't exist")
          return
        }

        const { isPublished } = doc.data()

        if (isPublished) {
          setEvent(doc.data())
          return
        }

        setError("This Rallista event doesn't exist")
      })
      .catch((error) => {
        setLoading(false)

        setError(error)
      })
  }, [authUser, firebase, documentID])

  // Links & Actions
  const appLink = `rallista://event/${documentID}`

  var outerTitle = "Loading..."
  var seoTitle = "Rallista Event"
  var seoDescription = "Shared event"

  var detailBlock = ""

  const tryOpenApp = () => {
    document.location = appLink

    setTimeout(function () {
      if (confirm("Do you need to download the app?")) {
        document.location = "https://rallista.app/download"
      }
    }, 300)
  }

  if (theEvent) {
    const { title, description, infoUrl, username, createdBy, photoUrl } =
      theEvent

    outerTitle = title
    seoTitle = `${title} - Rallista Event`
    seoDescription = description ? description : seoDescription

    detailBlock = (
      <>
        <strong>Created By</strong>
        <p>{createdBy || username}</p>
        <p>{description}</p>
        {infoUrl ? <a href={infoUrl}>Event Info</a> : null}
      </>
    )

    if (photoUrl) {
      firebase
        .storage()
        .ref()
        .child(photoUrl)
        .getDownloadURL()
        .then((url) => {
          setImageUrl(url)
        })
        .catch((error) => {
          console.error("Image (error):", error)
        })
    }
  }

  if (!loading && !theEvent) {
    outerTitle = "Not Found"
    detailBlock = <strong>{"This event is private or doesn't exist"}</strong>
  }

  if (error) {
    outerTitle = "Error Occured"

    detailBlock = (
      <strong>
        {
          "An error occured loading this event. You can still try and Open in App!"
        }
      </strong>
    )
  }

  const style = {
    backgroundImage: `url(${imageUrl})`,
  }

  return (
    <Page
      zeroTop
      seoTitle={seoTitle}
      seoDescription={seoDescription}
      seoImage={imageUrl}
    >
      <FancyHeader title={outerTitle} subTitle={"Rallista Event"} />
      <Container>
        <Row>
          <Col sm="12" md="4">
            <Row className="item-section">
              <Col>
                <Card className="share-item-card dark" style={style} />
              </Col>
            </Row>
            <Row className="item-section">
              <Col>
                <Button onClick={tryOpenApp} variant="outline-light">
                  Open in App
                </Button>
              </Col>
            </Row>
          </Col>
          <Col sm="12" md="8">
            <Row className="item-section">
              <Col>{detailBlock}</Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Page>
  )
}

EventPage.propTypes = {
  documentID: PropTypes.string.isRequired,
}

export default EventPage
